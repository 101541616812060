import type { FC } from "react";
import React, { useCallback, useMemo } from "react";

import { questionnaireFieldValidation } from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import Loader from "src/ui/components/Loader/Loader";
import Translate from "src/ui/components/Translate/Translate";
import { z } from "zod";

import {
  AutoForm,
  AutoFormDataField,
  AutoFormDataFieldDate,
  Button,
  DataFieldGroup,
  pickerCurrentTime,
  useAutoFormControls
} from "@9amhealth/shared";
import {
  getLocalTimeZone,
  now as internationalizedNow,
  now,
  ZonedDateTime
} from "@internationalized/date";
import { Blac } from "blac-next";
import { IconWeightScale } from "src/constants/icons";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { poundsToKg } from "src/lib/unitConversion";
import { validateMinDateTime } from "src/lib/zod/validateDate";
import LabResultsCubit from "src/state/LabResultsCubit/LabResultsCubit";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import { tracker } from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";
import ErrorBox from "src/ui/components/StyledComponents/ErrorBox";
import Track from "src/ui/components/Track/Track";

const WeightSchema = (minDate: ZonedDateTime) =>
  z.object({
    date: validateMinDateTime(minDate),
    weight: z.string().refine(
      (v) => {
        if (!v) return false;
        const regex = questionnaireFieldValidation.weight;
        return regex.test(v);
      },
      { message: translate("upload_labs.weight.invalid_input_error") }
    )
  });

type FormValues = z.infer<ReturnType<typeof WeightSchema>>;

const WeightForm: FC<{
  onComplete: () => void;
  text?: string;
  minDate?: ZonedDateTime;
}> = (props) => {
  const [errorMessage, setErrorMessage] = React.useState<TranslationKey>();

  const initialFormValues: FormValues = {
    weight: "",
    date: internationalizedNow(getLocalTimeZone())
  };

  const handleSubmit = useCallback(
    async (formValues: FormValues): Promise<void> => {
      let error;

      try {
        const weightInKg = poundsToKg(Number(formValues.weight));

        const [dateTimeZone] = formValues.date.toString().split("[");
        const observation = LabResultsCubit.createObservationWeight(
          weightInKg.toString(),
          dateTimeZone
        );

        error = await LabResultsCubit.storeSelfReportedLabValues(
          [observation],
          "messenger"
        );
        tracker.track(TrackEvent.WeightForm, {
          type: TrackType.complete
        });
        void Blac.getBloc(LabResultsCubit).loadObservations();
      } catch (observationError: unknown) {
        reportErrorSentry(observationError);
        error = (observationError as Error).message;
      }

      if (error) {
        setErrorMessage("error_generic");
      } else {
        props.onComplete();
      }
    },
    [props.onComplete]
  );

  const schema = useMemo(
    () =>
      WeightSchema(
        props.minDate ?? now(getLocalTimeZone()).subtract({ years: 3 })
      ),
    [props.minDate]
  );

  const autoFormControls = useAutoFormControls({
    schema,
    initialValue: initialFormValues,
    onSubmit: (data) => void handleSubmit(data as unknown as FormValues)
  });

  const handleFocusChange = () => {
    const isCoarse = matchMedia("(pointer:coarse)").matches;

    if (!isCoarse) {
      return;
    }
  };

  return (
    <div>
      <h3 className="as-h4 center">
        <div style={{ opacity: 0.2 }}>
          <IconWeightScale />
        </div>
        <Translate msg="upload_labs.weight.title" />
      </h3>

      {props.text && <p className="as-body center">{props.text}</p>}

      <nine-spacer s="md"></nine-spacer>

      <Track event={TrackEvent.WeightForm} type={TrackType.open} />

      <nine-spacer s="md"></nine-spacer>

      <AutoForm {...autoFormControls.props}>
        <DataFieldGroup>
          <AutoFormDataFieldDate
            onFocus={handleFocusChange}
            maxValue={pickerCurrentTime()}
            minValue={props.minDate}
            label={translate("upload_labs.weight.date_input_label")}
            name="date"
            granularity="minute"
            hideTimeZone
          />

          <AutoFormDataField
            inputMode="numeric"
            name="weight"
            mask="00[0]"
            label={translate("upload_labs.weight.value_input_label")}
            affix="lbs"
          />
        </DataFieldGroup>

        <nine-spacer s="lg" />

        <Button
          type="submit"
          theme="charcoal"
          style={{ color: "white", margin: "auto" }}
        >
          {translate("confirm")}
        </Button>
      </AutoForm>

      {errorMessage && (
        <>
          <nine-spacer s="sm"></nine-spacer>
          <ErrorBox data-severity="error">
            <Translate msg={errorMessage} fallback="error_generic" />
          </ErrorBox>
        </>
      )}

      <Loader fixed absolute background overContent />
    </div>
  );
};

export default WeightForm;
